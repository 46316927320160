import React, { useState, useEffect } from "react"
import { detectMob } from "@src/Helper"
import SEO from "@components/seo"

function RefundPolicy() {
  const [id, setId] = useState("refund-policy")

  useEffect(() => {
    if (detectMob()) {
      setId("m-refund-policy")
    }
  }, [])
  return (
    <section id={id} className="section-150">
      <SEO title="Refund Policy" />
      <div className="container">
        <div style={{ marginBottom: 50 }} className="text-center">
          <div className="title">Refund Policy</div>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">Executive Mafia Refund Policy</h3>
          <p>
            We, PT. Eksekutif Media Utama are committed to providing the best
            service possible, at all times. Within our services, we do offer
            refunds under these policies:
          </p>

          <ol>
            <li>
              System failed to provide service or services during a calendar
              week.
            </li>
            <li>Accidentally purchased the wrong subscription package.</li>
            <li>Unexpected double payment following the transaction.</li>
            <li>
              If our services work as promised but then you decide that you no
              longer wish to continue:
              <br />A refund will NOT be possible as there is no issue with the
              services and every package that has been purchased is a monthly
              subscription. If a member does not remember to cancel their
              subscription, a refund can not be possible regardless of the
              reasons.
            </li>
            <li>
              Expecting something that is not written on the website:
              <br />
              We will NOT refund the payment if the member in question did not
              clearly read and then expect certain features that were not
              described and written on the website.
            </li>
          </ol>

          <p>
            After the refund request is validated and approved by our admin, a
            refund can only be given within 3 DAYS after the transaction is
            done.
          </p>

          <p>
            Any transaction fee of the refund will be deducted from the total
            amount of refund.
          </p>

          <p>
            Any form of Scam and Defamation will be reported under the
            applicable law and block you from ever using our services and
            network in the future.
          </p>

          <h3 className="subtitle">Creating a case on PayPal</h3>

          <p>
            We, PT. Eksekutif Media Utama will do our best to resolve any issues
            regarding your transaction and payment.
          </p>

          <ol type="1">
            <li>
              If you have an issue regarding your transaction and payment, you
              can contact us to: support@executivemafia.com along with your
              contact details and we will help you resolve it.
            </li>
            <li>
              If you go directly to PayPal and create a case against us, we will
              have to terminate your account and block you from ever using our
              services and network in the future.
            </li>
          </ol>

          <p>
            Thank you for reading our Refund Policy. If you have any questions
            regarding as written above, do leave us a message via email to{" "}
            <a href="mailto:support@executivemafia.com">
              support@executivemafia.com
            </a>{" "}
            or via mail to:
          </p>

          <p>
            PT. EKSEKUTIF MEDIA UTAMA
            <br />
            21, JALAN MELATI, KOMPLEK CEMARA ASRI
            <br />
            MEDAN CITY, NORTH SUMATRA 20371
            <br />
            INDONESIA
            <br />
          </p>
        </div>
      </div>
    </section>
  )
}

export default RefundPolicy
